/**
 *  Description: This file stores all Constants with values, add new version changes

 * @author {Cognizant Technology Solution}
 */
const siteCoreVersion = `/v5/`;
const customApiVersion = `/v5/`;

// general constants
export const GSEARCH_LINK = "https://www.microsoft365.com/search?q=";
export const GNET_NEWS = "G.Net News";

export const EVENTS = "Events";

export const NOTICES = "Notices";

export const GLINK_CATEGORYNAME = {
  [GNET_NEWS]: "News",
  [EVENTS]: "Events",
  [NOTICES]: "Events",
};
export const PAGES = "pages";

export const PEOPLEONLY = "peopleonly";

export const NEWSDETAILS = `/Home/NewsDetails`;

export const NEWS_ROOM = `/Home/NewsRoom`;

export const USER_PROFILE = "/Home/UserProfile";

export const KITENET_NEWSROOM = `/Home/KiteNet/KiteNetNewsRoom`;

export const KITENETNEWSDETAILS = `/Home/KiteNet/KiteNewsDetails`;

export const KITE_NET = `/Home/KiteNet`;

export const EVENTSNOTICES = "Events & Notices";

export const KITENEWS = "KiteNet News";

export const Press_Releases = "Press Releases";

export const Stories_AT_Gilead = "Stories@Gilead";

export const Social_Media = "Social Media";



// site core endpoints
export const GNET_NEWSDETAILS = `${siteCoreVersion}getnewsdetails?itemName=ITEM_NAME`;

export const KITENET_NEWSDETAILS = `${siteCoreVersion}getkitenewsdetails?itemName=ITEM_NAME`;

export const TOPBANNER_USER_PROFILES = {
  endpoint: `${siteCoreVersion}userprofiles?itemid=ITEM_ID`,
  isCaching: false,
};
export const HEADER_FOOTER = {
  endpoint: `${siteCoreVersion}getnavigationdetails?userprimarylocation=USER_PRIMARY_LOCATION&locationps=USER_CURRENT_LOCATION&lobCode=USER_LOBCODE`,
  isCaching: true,
};
export const WELCOMBANNERGET = `${siteCoreVersion}getWelcomeBanner`;

export const NEWSROOM = `${siteCoreVersion}newsroomdetails`;

export const KITE_NEWSROOM = `${siteCoreVersion}getkitenewsroomdetails`;

export const GET_LOCATION_TIMEZONE = `${siteCoreVersion}getlocationtimezones`;

export const EVENTS_NOTICES = `${siteCoreVersion}eventsnotices?preview=true`;

export const GETKITEHOMEDETAIL = `${siteCoreVersion}getkitehomedetails`;

export const GET_MY_DASHBOARD = `${siteCoreVersion}getmydashboarditems`;

export const TOPBANNERGET = `${siteCoreVersion}gettopbanner`;

export const GETHOMEPAGEDETAILS = {
  endpoint: `${siteCoreVersion}getHomePageDetails`,
  isCaching: true,
  cacheTime: 20,
};

export const IMAGE_UPLOAD = `${siteCoreVersion}imageuploads?preview=true`;

// custom API
export const LOCATIONKEY =
  "https://api.accuweather.com/locations/v1/cities/search?q=LATITUDE,LONGITUDE&apikey=APIKEY";

export const WEATHERDETAILS =
  "https://api.accuweather.com/currentconditions/v1/LOCATIONKEY?apikey=APIKEY";

export const GETBOOKMARK = `${customApiVersion}bookmarks?isPaged=false&rowlimit=12`;

export const ADDBOOKMARK = `${customApiVersion}bookmarks`;

export const DELETEBOOKMARK = `${customApiVersion}bookmarks`;

export const LIKES = `${customApiVersion}likes`;

export const LEAVEFEEDBACK = `${customApiVersion}leavefeedback`;

export const USERPROFILES = { endpoint: `${customApiVersion}userprofiles?api-version=2`, isCaching: false };

export const WELCOMBANNERPOST = `${customApiVersion}welcomebanner`;

export const ADD_TO_FAVOURITE = `${customApiVersion}myfavorites`;

export const MY_PREFERENCE = `${customApiVersion}mypreference`;

export const USER_WORLD_CLOCK_POST = `${customApiVersion}userworldclock`;

export const apigeeTokenGetter =
  "https://api-uat.gaim.gilead.com/v2/jwttoken/generate?client_id=obZGc3jloIf5VVbLrnou9EkW4mdOq75L";

export const EDIT_MYDASHBOARD = `${customApiVersion}mydashboard`;

export const USERPRIMARYLOCATION = `${customApiVersion}userprimarylocation`;


export const peopleSearch = `${customApiVersion}peoplesearch`;

export const GET_USERPROFILES_DETAILS = `${customApiVersion}getuserprofiles`;

export const GET_SPO_SEARCH = `${customApiVersion}sposearch`;

export const SPO_SEARCH = `${customApiVersion}sposearch`;

export const PEOPLE_PICKER = `${customApiVersion}peoplepicker`;

export const VIDEO_API = `${customApiVersion}getimageorvideo`;

export const GET_EMPLOYEE_BADGE = `${customApiVersion}userteaminformation`;

export const SEND_TOP_BANNER_DATA = `${customApiVersion}topbanner`;

export const SEND_FLAG_DATA = `${customApiVersion}searchcontentflag`;

